import { compile } from 'path-to-regexp'
import { ValueOfArray, Tail, Head, Prepend } from './types'

export class RoutePatternResult<Param extends string> {
  public readonly pattern: string

  /**
   * Interperolate arguments into the route pattern
   */
  readonly toUrl: (args: { [K in Param]: string }) => string

  constructor(pattern: string) {
    this.pattern = pattern
    this.toUrl = compile(this.pattern)
  }
}

/**
 * Tag a template string with route data
 */
export function route<
  Param extends string,
  ParamOrPatternResult extends Array<Param | RoutePatternResult<string>>,
>(
  strings: TemplateStringsArray,
  ...paramNames: ParamOrPatternResult
): RoutePatternResultFromParams<ParamOrPatternResult> {
  const pattern = strings.reduce((accum, str, i) => {
    let result = accum + str
    const paramName = paramNames[i]

    if (paramName instanceof RoutePatternResult) {
      result += paramName.pattern
    } else if (typeof paramName === 'string') {
      result += ':' + paramName
    }

    return result
  }, '')

  // Without casting to any, we get the following error:
  // > Type instantiation is excessively deep and possibly infinite.
  return new RoutePatternResult<Param>(pattern) as any
}

type RoutePatternResultFromParams<
  Params extends Array<string | RoutePatternResult<string>>,
  Result extends string[] = [],
> = {
  returnResult: RoutePatternResult<ValueOfArray<Result>>
  recurse: RoutePatternResultFromParams<Tail<Params>, Prepend<ExtractParam<Head<Params>>, Result>>
}[Head<Params> extends never ? 'returnResult' : 'recurse']

export type ExtractParam<T extends string | RoutePatternResult<string>> =
  T extends RoutePatternResult<infer S> ? S : T

export type ExtractRouteParamsInterface<T extends string | RoutePatternResult<string>> = {
  [K in ExtractParam<T>]: string | undefined
}
