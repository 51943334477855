import React from 'react'
import { graphql, PageProps, Link } from 'gatsby'
import '../../styles/base.css'
import { routes } from '../../routes'

export const query = graphql`
  query {
    posts: allGoogleDoc {
      nodes {
        id
        title
      }
    }
  }
`

const HomePage: React.FC<PageProps<any>> = ({ data, pageContext }) => {
  const posts = data.posts.nodes

  return (
    <div>
      <h1>Hello, world!</h1>
      {posts.map((post: any) => (
        <h2 key={post.id}>
          <Link to={routes.post.toUrl({ postSlug: post.id })}>{post.title}</Link>
        </h2>
      ))}
    </div>
  )
}

export default HomePage
